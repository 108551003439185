import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    /** If a user is logged in. */
    public isLoggedIn: boolean = false;

    public tutorialStage: number = 0;
    public isTutorialRunning: boolean = false;

    public isNotification: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isLimitedRoute: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _queryParams: any = null;
    private _subscriptions: Subscription[] = [];
    constructor(
        private _authService: AuthService,
        private _elRef: ElementRef,
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) { }

    ngOnInit() {
        let authSubscription = this._authService.isAuthenticated().subscribe((value) => {
            this.isLoggedIn = value ?? false;
        }, console.error)
        this._subscriptions.push(authSubscription);

        this._subscriptions.push(this._activatedRoute.queryParams.subscribe((params) => {
            this._queryParams = params;
            if ("true" === params["tutorial"] || "1" === params["tutorial"]) this.startTutorial()
        }))
    }

    ngOnDestroy() {
        for (let sub of this._subscriptions) {
            if (sub) sub.unsubscribe();
        }
    }

    /**
     * Starts the tutorial for the system.
     * @returns {void}
     * @memberof AppComponent
     */
    public startTutorial(): void {
        this.tutorialStage = 0;
        this.isTutorialRunning = true;
        setTimeout(() => {
            this.progressTutorial();
        }, 100)
    }

    /**
     * Move to the next stage of the tutorial defined by available tutorial components and order.
     * @returns {void}
     * @memberof AppComponent
     */
    public progressTutorial(): void {
        let tutorialElements: any[] = this._elRef.nativeElement.querySelectorAll(".tutorial-element");
        let tutorialElementsList: HTMLElement[] = [];

        //Mark all elements as hidden and add the element to the list so it can be sorted
        for (let tutorialElement of tutorialElements) {
            tutorialElement.style.visibility = "hidden"
            tutorialElementsList.push(tutorialElement)
        }

        //Sort the list based on available ordering
        tutorialElementsList = tutorialElementsList.sort((a, b) => {
            let aNumber = Number(a.getAttribute("tutorialOrder") ?? 0) || 0
            let bNumber = Number(b.getAttribute("tutorialOrder") ?? 0) || 0
            return aNumber - bNumber
        })

        //Copy the active element
        let tutorialElement: HTMLElement = tutorialElementsList[this.tutorialStage];

        //If there are no more tutorial elements disable the tutorial
        if (!tutorialElement) return this.stopTutorial()

        // Scroll to the tutorial element
        tutorialElement.parentElement.scrollIntoView();

        //Make the element visible
        tutorialElement.style.visibility = "visible"

        this.tutorialStage++;
    }

    /**
     * Stops the tutorial for the system.
     * @returns {void}
     * @memberof AppComponent
     */
    public stopTutorial(): void {
        this.tutorialStage = 0;
        this.isTutorialRunning = false;

        //Navigate to the same route without the tutorial parameter
        if (this._queryParams) {

            // Copy the existing query parameters to a new object without tutorial
            // Deleting tutorial from the original was not allowed
            let newQueryParams = {}
            for (let queryParamName in this._queryParams) {
                if ("tutorial" == queryParamName) continue;
                newQueryParams[queryParamName] = this._queryParams[queryParamName]
            }
            // http://localhost:4200/module?id=6&tutorial=
            let route = this._router.url.split("?")[0]
            //Navigate to the route
            this._router.navigate([route], {
                queryParams: newQueryParams
            })

        }
    }
}