<div class="psi-chart-container">
    <div class="psi-chart" #chart>
        <div #xAxisLabel class="hidden" i18n>Learning Aspects</div>
        <div #yAxisLabel class="hidden" i18n>Level Achieved</div>
        <div #yAxisUnit class="hidden" i18n>%</div>
        <div #dataLabel class="hidden" i18n>Your Current Status</div>
        <canvas *ngIf="barChartData" baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
        </canvas>
    </div>


    <div class="achievement-description-container">

        <h1 i18n>Learning Progress</h1>

        <!-- Expertise Description -->
        <div>
            <div *ngIf="ExpertiseLevel.Novice == expertiseLevel">
                <h2 i18n>Novice</h2>
                <p i18n>
                    Educators performing at a novice level tend to rely on predetermined, structured curriculum (e.g. a textbook) and
                    deliver information without significant context. They have content but minimal pedagogical knowledge. They clearly
                    and objectively recognize the elements required to perform their duties. They depend on rules and procedures to
                    make decisions, but often have difficulty predicting the outcome due to lack of experience.
                </p>
            </div>

            <div *ngIf="ExpertiseLevel.AdvancedBeginner == expertiseLevel">
                <h2 i18n>Advanced Beginner</h2>
                <p i18n>
                    Advanced beginners have accumulated some teaching experience. They begin to recognize situations that occur in
                    their day-to-day teaching practice, and although they often rely on the externally-provided rules and procedures,
                    they begin to form their own set based on their own experiences, good or bad. They are usually very organized in
                    what and how they deliver content. They start tapping into the skills and knowledge of expert teachers, and begin to
                    acknowledge that the learner plays a significant role in the teaching process. At this level educators are now
                    developing pedagogical knowledge.
                </p>
            </div>

            <div *ngIf="ExpertiseLevel.Competent == expertiseLevel">
                <h2 i18n>Competent</h2>
                <p i18n>
                    Educators performing at the competent level have a good understanding of various instructional methods and are
                    better able to prioritize what and how information should be packaged for delivery. Assessments and outcomes
                    become aligned and provide useful feedback to learners. Competent educators have expanded pedagogical
                    knowledge and a sense of curricular knowledge. Many stay at this phase for the duration of their career.
                </p>
            </div>

            <div *ngIf="ExpertiseLevel.Proficient == expertiseLevel">
                <h2 i18n>Proficient</h2>
                <p i18n>
                    Proficient educators see the curriculum more holistically and are able to make connections between what they are
                    teaching and the broader discipline. They recognize that they can make a difference in the lives and careers of their
                    students, and their focus moves from teaching to learning. Instructors’ curricular knowledge has expanded and they
                    are beginning to accumulate pedagogical content knowledge.
                </p>
            </div>

            <div *ngIf="ExpertiseLevel.Expert == expertiseLevel">
                <h2 i18n>Expert</h2>
                <p i18n>
                    Expert educators are more spontaneous in their presentations and more intuitive in their assessment. Their methods
                    become more experimental, recognizing that the outcome is not guaranteed. Expert educators also model what it
                    means to be a professional in their discipline, inspiring students to become that practitioner. They are concerned
                    about the success of their learners and shape the learning experience to support that success. Expert educators
                    regularly apply pedagogical content knowledge in their classes.
                </p>
            </div>

        </div>

        <!-- Expertise Progress -->
        <div>
            <p *ngIf="QualitativeLevel.Low == getOverallQualitativeLevel()" i18n>
                It seems that you've just moved into this phase of your teaching journey and still have some aspects of
                establishing an effective learning environment in which you can grow. Work through and implement the ideas in the
                recommended nano-learning module to strengthen your teaching skills.
            </p>
            <p *ngIf="QualitativeLevel.Close == getOverallQualitativeLevel()" i18n>
                At this point in your teaching journey, you still have some aspects of establishing an effective learning
                environment in which you can grow. Work through and implement one of the recommended nano-learning modules
                and see what happens!
            </p>
            <p *ngIf="QualitativeLevel.Good == getOverallQualitativeLevel()" i18n>
                You seem capable and comfortable at this stage in your teaching journey, keeping in mind that there are always
                opportunities to grow. Work through and implement one of the recommended nano-learning modules to enhance
                your teaching practice.
            </p>
            <p *ngIf="QualitativeLevel.Great == getOverallQualitativeLevel()" i18n>
                You are showing strength at this point in your teaching journey. Work through and implement one of the
                recommended nano-learning modules and see how you can enhance your teaching practice even more!
            </p>
            <p *ngIf="QualitativeLevel.High == getOverallQualitativeLevel()" i18n>
                You are showing strength at this point in your teaching journey. With a bit more effort you can move yourself up to
                the next phase in your development Work through and implement one of the recommended nano-learning modules
                and see what happens!
            </p>
        </div>



        <!-- Learning Aspect Tabs -->
        <mat-tab-group [selectedIndex]="activeTab.value" (selectedIndexChange)="activeTab.setValue($event)" dynamicHeight id="aspectDescription">
            <mat-tab *ngFor="let learningAspect of getLearningAspects()">

                <!-- Tab Label -->
                <ng-template mat-tab-label>
                    {{ getPreset(learningAspect) }}

                    <!-- Notification indicator -->
                    <span class="notification-badge" *ngIf="notificationStatus && isNotification(learningAspect)"></span>
                </ng-template>

                <!-- Tab Content -->
                <div class='tab-content'>
                    <h2 i18n>{{ getPreset(learningAspect) }}</h2>
                    <div *ngIf="true">
                        <p>
                            <span *ngIf="QualitativeLevel.Low == getAspectQualitativeLevel(learningAspect)">
                                <span>
                                    You've achieved some of the objectives for this aspect, but there's room for growth{{ learningAspect == getWeakestAspect() ? ',' : '.'}}
                                </span>


                                <!-- If this is the weakest aspect -->
                                <span *ngIf="learningAspect == getWeakestAspect()" i18n>
                                    more so here than for the other aspects.
                                </span>

                            </span>

                            <span *ngIf="QualitativeLevel.Close == getAspectQualitativeLevel(learningAspect)">
                                <span>
                                    You've achieved some of the objectives for this aspect, but there's room for growth{{ learningAspect == getWeakestAspect() ? ',' : '.'}}
                                </span>

                                <!-- If this is the weakest aspect -->
                                <span *ngIf="learningAspect == getWeakestAspect()" i18n>
                                    more so here than for the other aspects.
                                </span>

                            </span>

                            <span *ngIf="QualitativeLevel.Good == getAspectQualitativeLevel(learningAspect)">
                                <span i18n>
                                    Your efforts in this area are aligned with someone at this stage in their teaching journey.
                                </span>

                                <!-- If this is the weakest aspect -->
                                <span *ngIf="learningAspect == getWeakestAspect()" i18n>
                                    It is, however, your lowest ranked aspect of an effective learning environment, so you may want to look for ways you can enhance your
                                </span>

                                <!-- If another aspect is weaker -->
                                <span *ngIf="learningAspect != getWeakestAspect()" i18n>
                                    Since this is an area where you show some strength, you may want to focus on enhancing
                                </span>

                            </span>

                            <span *ngIf="QualitativeLevel.Great == getAspectQualitativeLevel(learningAspect)">

                                <span i18n>
                                    Your efforts in this area are great for someone at this stage in their teaching journey.
                                </span>

                                <!-- If this is the weakest aspect -->
                                <span *ngIf="learningAspect == getWeakestAspect()" i18n>
                                    It is, however, your lowest ranked aspect of an effective learning environment, so you may want to look for ways you can enhance your
                                </span>

                                <!-- If another aspect is weaker -->
                                <span *ngIf="learningAspect != getWeakestAspect()" i18n>
                                    Since this is an area where you show some strength, you may want to focus on enhancing
                                </span>

                            </span>

                            <span *ngIf="QualitativeLevel.High == getAspectQualitativeLevel(learningAspect)">
                                <span i18n>
                                    Your efforts in this area are exceptional for someone at this stage in their teaching journey.
                                </span>

                                <!-- If this is the weakest aspect -->
                                <span *ngIf="learningAspect == getWeakestAspect()" i18n>
                                    It is, however, your lowest ranked aspect of an effective learning environment, so you may want to look for ways you can enhance your
                                </span>

                                <!-- If another aspect is weaker -->
                                <span *ngIf="learningAspect != getWeakestAspect()" i18n>
                                    Since this is an area where you show some strength, you may want to focus on enhancing
                                </span>

                            </span>

                            <!-- End of Statements - Low Level -->
                            <span *ngIf="[QualitativeLevel.Low, QualitativeLevel.Close].includes(getAspectQualitativeLevel(learningAspect))">

                                <span i18n>
                                    Look for ways to
                                </span>

                                <!-- 'Focus on Learning' End of Statement -->
                                <span *ngIf="'FoL' == learningAspect" i18n>
                                    integrate more active or collaborative learning into your class time or incorporate learning opportunities that
                                    encourage deeper and/or critical thinking.
                                </span>

                                <!-- 'Supported Instruction' End of Statement -->
                                <span *ngIf="'SUP' == learningAspect" i18n>
                                    incorporate more opportunities to provide feedback for your students or uncover and focus on the concepts that
                                    are critical within your discipline.
                                </span>

                                <!-- 'Quality of Teaching' End of Statement -->
                                <span *ngIf="'QoT' == learningAspect" i18n>
                                    ensure your classes are level- and pace-appropriate, while maintaining a caring, respectful, and accessible
                                    demeanor.
                                </span>

                                <!-- 'Establishing Relationships' End of Statement -->
                                <span *ngIf="'REL' == learningAspect" i18n>
                                    establish good working relationships with and amongst your students or support their learning by listening to their
                                    feedback.
                                </span>

                                <!-- 'Student Engagement' End of Statement -->
                                <span *ngIf="'ENG' == learningAspect" i18n>
                                    provide value in the tasks you assign both in and beyond the classroom.
                                </span>

                                <!-- 'Acedemic Rigour' End of Statement -->
                                <span *ngIf="'RIG' == learningAspect" i18n>
                                    ensure active learning of meaningful content with higher-order thinking at (or above) appropriate expectations.
                                </span>
                            </span>

                            <!-- End of Statements - High Level -->
                            <span *ngIf="![QualitativeLevel.Low, QualitativeLevel.Close].includes(getAspectQualitativeLevel(learningAspect))">
                                {{ getPreset(getWeakestAspect()) }}.
                            </span>


                        </p>
                    </div>

                </div>

            </mat-tab>
        </mat-tab-group>
    </div>


    <div #chartTextPresets style="display: none;">

        <!-- Learning Aspects -->
        <div id="FoL" i18n>Focus on Learning</div>
        <div id="SUP" i18n>Supported Instruction</div>
        <div id="QoT" i18n>Quality of Teaching</div>
        <div id="REL" i18n>Establishing Relationships</div>
        <div id="ENG" i18n>Student Engagement</div>
        <div id="RIG" i18n>Academic Rigour</div>

        <!-- Expertise -->
        <div id="Expertise-0" i18n>Novice</div>
        <div id="Expertise-1" i18n>Advanced Beginner</div>
        <div id="Expertise-2" i18n>Competent</div>
        <div id="Expertise-3" i18n>Proficient</div>
        <div id="Expertise-4" i18n>Expert</div>

    </div>
</div>