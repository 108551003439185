import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Notification, } from '@services/backendClient';
import { NotificationService } from '@services/notification.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'psi-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

    @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger;

    @Input() drawer?: MatDrawer;
    @Input() isChartNotification?: BehaviorSubject<boolean>;

    /** If the navigation bar has limited options due to the current route. */
    @Input() isLimitedRoute: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

    /** Routes that limit the options of the navigation bar. */
    private _limitedRoutes: string[] = [
        "/onboarding"
    ]

    private _subscriptions: Subscription[] = [];
    constructor(
        private _notificationService: NotificationService,
        private _authService: AuthService,
        private _router: Router,
        private _route: ActivatedRoute,
    ) { }

    async ngOnInit(): Promise<void> {
        let authSubscription = this._authService.isAuthenticated().subscribe(async (isAuthenticated) => {
            if (true === isAuthenticated)
                await this.loadNotifications()
        }, console.error)

        this.onRouteChange(this._router.url)
        let routeSubscription = this._router.events
            .pipe(filter(routerEvent => routerEvent instanceof NavigationEnd))
            .subscribe((routerEvent: NavigationEnd) => {
                this.onRouteChange(routerEvent.urlAfterRedirects || routerEvent.url)
            })
        this._subscriptions.push(authSubscription)
        this._subscriptions.push(routeSubscription)
    }

    ngOnDestroy(): void {
        for (let sub of this._subscriptions) {
            if (sub) sub.unsubscribe();
        }
    }

    public async loadNotifications(): Promise<void> {
        try {
            this.notifications = this._notificationService.notifications;
            return Promise.resolve()
        } catch (e) {
            console.error("Failed to load notifications:", e)
            return Promise.resolve()
        }
    }

    public runTemplate(pattern: string, data: any): string {
        const matcher = /{{\s?([^{}\s]*)\s?}}/g;
        return pattern.replace(
            matcher,
            (_, value, __) => {
                let newValue = data[value];
                if (value.toLowerCase().endsWith("date")) {
                    let timestamp = Date.parse(newValue);
                    if (timestamp !== NaN) {
                        return new Date(timestamp);
                    }
                }
                return data[value]
            });
    }

    public dateToRelativeString(date: Date): string {
        return date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
    }

    public dismissNotification(notification: Notification): void {
        try {
            this._notificationService.dismiss(notification.id);
            this.notifications.next(this.notifications.value.filter(v => v.id !== notification.id));
        } catch (e) {
            throw e;
        }
    }

    public async triggerNotification(notification: Notification): Promise<void> {

        if (notification.url) {

            //Close the menu when following a notification route
            this.matMenu.closeMenu();

            //Navigate to the target route
            let route;
            try {
                route = new URL(notification.url);
            } catch {
                route = new URL(notification.url, window.location.origin);
            }
            await this._router.navigate([route.pathname], { queryParams: Object.fromEntries(route.searchParams.entries()) });
        } else {
            this.dismissNotification(notification);
        }
    }

    public logout(): void {
        this._authService.logout();
    }


    /**
     * Starts the selected module.
     */
    public gotoDashboard() {
        this._router.navigate(["./dashboard"])
    }

    public gotoFeedbackSurvey() {
        this._router.navigate(["./feedback"], { queryParams: { id: "feedback2" } })
    }

    public onRouteChange(path: string): void {
        //Check if the current route is a limited nav bar route
        for (let limitedRoute of this._limitedRoutes) {
            if (!path.startsWith(limitedRoute)) continue
            this.isLimitedRoute.next(true);
            return
        }
        this.isLimitedRoute.next(false);
    }

}
