import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { NotificationsClient, Notification, CaptureRedirectError } from "./backendClient";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

/** The amount of time to wait before checking for more notifications. */
const NOTIFICATION_POLL_DELAY = 60000;

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private _client: NotificationsClient;

    private _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([])
    constructor(
        private _authService: AuthService,
        router: Router
    ) {
        this._client = new NotificationsClient(router, environment.apiUrl);

        setInterval(() => {
            this.poll();
        }, NOTIFICATION_POLL_DELAY);
        this.poll();
    }

    public get notifications() { return this._notifications };

    public async dismiss(id: number): Promise<void> {
        this._client.token = await this._authService.getToken();
        return await this._client.dismiss(id);
    }

    /**
     * Loads notifications from the server and updates the notifications behavior subject.
     *
     * @returns {Promise<void>}
     * @memberof NotificationService
     */
    public async poll(): Promise<void> {
        console.log("Polling for notifications.")
        this._client.token = await this._authService.getToken();
        const notifications = await CaptureRedirectError(async () => await this._client.get());
        if (notifications !== null && JSON.stringify(notifications) !== JSON.stringify(this._notifications.value)) {
            this._notifications.next(notifications)
        }
    }

}