import { Input, OnDestroy } from '@angular/core';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StorageService } from '@services/storage.service';
import { ChartColor, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, Subscription, TimeInterval } from 'rxjs';
import { ExpertiseLevel } from '@shared/enums/ExpertiseLevel'
import { QualitativeLevel } from '@shared/enums/QualitativeLevel';
import { PSIAchievementLevel } from '@shared/types/PSIChart';
import { DashboardService } from '@services/dashboard.service';
import { LensProgressResponse } from '@services/backendClient';
import { distinct } from 'rxjs/operators';

const LABEL_SCALAR = 1.25;
const DEFAULT_FONT_SIZE = 16;
const DASHBOARD_NOTIFICATION_STORAGE_KEY: string = "aspect-notifications"
const NOTIFICATION_CLEAR_DELAY = 1500;

@Component({
    selector: 'psi-achievement-chart',
    templateUrl: './achievement-chart.component.html',
    styleUrls: ['./achievement-chart.component.scss']
})
export class AchievementChartComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild("chart", { static: true }) chartContainer: ElementRef;
    @ViewChild("xAxisLabel", { static: true }) xAxisLabel: ElementRef;
    @ViewChild("yAxisLabel", { static: true }) yAxisLabel: ElementRef;
    @ViewChild("yAxisUnit", { static: true }) yAxisUnit: ElementRef;
    @ViewChild("dataLabel", { static: true }) dataLabel: ElementRef;
    @ViewChild("chartTextPresets", { static: true }) textPresets: ElementRef;

    //Chart configurations
    @Input() isLegend: boolean = true;
    @Input() minimum: number = 0;
    @Input() maximum: number = 250;
    @Input() competentAt: number = 100;

    //Data
    @Input() achievementLevels?: PSIAchievementLevel[] = [];
    @Input() expertiseLevel?: ExpertiseLevel = ExpertiseLevel.Novice;
    @Input() expertiseThresholds?: number[] = [];
    @Input() isChartNotification?: BehaviorSubject<boolean>;

    public barChartOptions: ChartOptions;
    public barChartLabels: Label[];
    public barChartType: ChartType = 'bar';
    public barChartLegend: boolean;
    public barChartData: ChartDataSets[];

    private _refreshInterval: any;
    public notificationStatus: Record<string, NotificationRecord>;

    private _expertiseColours: Record<ExpertiseLevel, { "borderColor": ChartColor, "color": ChartColor, "hoverColor": ChartColor }> = {
        [ExpertiseLevel.Novice]: { borderColor: "#707070", color: "#999", hoverColor: "#b8b8b8" },
        [ExpertiseLevel.AdvancedBeginner]: { borderColor: "#26812b", color: "#319236", hoverColor: "#46b34a" },
        [ExpertiseLevel.Competent]: { borderColor: "#3e3cde", color: "#4c51f7", hoverColor: "#676ffb" },
        [ExpertiseLevel.Proficient]: { borderColor: "#802fa8", color: "#9d4dbb", hoverColor: "#af6bc7" },
        [ExpertiseLevel.Expert]: { borderColor: "#f28c14", color: "#f3af19", hoverColor: "#f5c532" },
    }

    public activeTab: FormControl = new FormControl(0);

    /** The active subscriptions for the component. */
    private _subscriptions: Subscription[] = [];

    constructor(
        private _dashboardService: DashboardService,
        private _storage: StorageService
    ) { }

    async ngOnInit(): Promise<void> {
        //Load the lens values
        let previousLensResponseValue: LensProgressResponse;
        await this._dashboardService.getLens()
        let lensBehaviourSubject = this._dashboardService.lensBehaviourSubject;
        this._subscriptions.push(lensBehaviourSubject.subscribe(async (lensResponseValue) => {
            if (null !== lensResponseValue && JSON.stringify(previousLensResponseValue) !== JSON.stringify(lensResponseValue)) {
                await this.initialize(lensResponseValue);
                previousLensResponseValue = lensResponseValue
            }
        }))
    }


    ngAfterViewInit(): void {

    }

    async ngOnDestroy(): Promise<void> {
        for (let subscription of this._subscriptions) {
            if (subscription) subscription.unsubscribe();
        }
        if (this._refreshInterval) clearInterval(this._refreshInterval)
    }

    public async initialize(lensResponse: LensProgressResponse): Promise<void> {

        //Copy the data for the chart
        let { expertise, expertiseThresholds, values: achievementLevels } = lensResponse;
        this.achievementLevels = <PSIAchievementLevel[]><unknown>achievementLevels;
        this.expertiseLevel = expertise;
        this.expertiseThresholds = expertiseThresholds;

        //Convert input from html attribute because it comes in as a string
        this.expertiseLevel = isNaN(Number(this.expertiseLevel)) ? ExpertiseLevel.Novice : Number(this.expertiseLevel);
        this.initGraph();
        let notificationStatus = await this.getNotificationStatus()
        this.notificationStatus = await this.updateNotificationStatus(notificationStatus, this.achievementLevels)
        this.updateToolbarNotification();

        //Clear the notification from the 
        this.activeTab.valueChanges.subscribe(() => {
            let initialValue = this.activeTab.value;
            setTimeout(() => {
                if (initialValue == this.activeTab.value) {
                    let value = this.achievementLevels[initialValue].level
                    let aspect: string = this.achievementLevels[initialValue].aspect.id;
                    this.clearNotification(aspect, value);
                }
            }, NOTIFICATION_CLEAR_DELAY)
        })

    }

    /**
     * Returns if the notification should be enabled for the specified aspect.
     * @param {string} aspect The learning aspect.
     * @returns {boolean}
     * @memberof AchievementChartComponent
     */
    public isNotification(aspect?: string): boolean {

        if (aspect) {
            //Find the associated achievement value
            let achievementLevel: PSIAchievementLevel = this.achievementLevels.find((a) => {
                return a?.aspect?.id == aspect;
            })
            if (!achievementLevel) return false;

            //Copy the existing value
            let notificationStatus = this.notificationStatus[aspect] || {
                previousValue: achievementLevel.level,
                hasChecked: false
            }
            return !notificationStatus.hasChecked || achievementLevel.level != notificationStatus.previousValue;
        } else {

            for (let achievementLevel of this.achievementLevels) {

                //Copy the existing value
                let notificationStatus = this.notificationStatus[achievementLevel?.aspect?.id] || {
                    previousValue: achievementLevel.level,
                    hasChecked: false
                }

                let isNotification: boolean = !notificationStatus.hasChecked || achievementLevel?.level != notificationStatus.previousValue;
                if (isNotification) return isNotification;
            }
            return false;
        }

    }

    /**
     * Update the variable responsible for toolbar notifications.
     * @returns {void}
     * @memberof AchievementChartComponent
     */
    public updateToolbarNotification(): void {

        if ("undefined" === typeof this.isChartNotification) return;

        //Update the global notification status
        let isNotification = this.isNotification();
        if (isNotification != this.isChartNotification.value) {
            this.isChartNotification.next(isNotification);
        }
    }

    /**
     * Clears a notification from an aspect and resaves the statuses
     * @param {string} aspect The learning aspect.
     * @param {number} value The updated value for the status.
     * @returns {Promise<void>}
     * @memberof AchievementChartComponent
     */
    public async clearNotification(aspect: string, value: number): Promise<void> {

        //Updates the value
        this.notificationStatus[aspect] = {
            previousValue: value,
            hasChecked: true
        }

        //Resaves the notification statuses
        await this._storage.set(DASHBOARD_NOTIFICATION_STORAGE_KEY, this.notificationStatus);

        //Update the toolbar notification if there are now no notifications
        this.updateToolbarNotification();

    }


    /**
     * Loads the notification statuses from local storage.
     * @returns {Promise<Record<string, NotificationRecord>>}
     * @memberof AchievementChartComponent
     */
    public async getNotificationStatus(): Promise<Record<string, NotificationRecord>> {
        return await this._storage.get(DASHBOARD_NOTIFICATION_STORAGE_KEY) || {}
    }

    /**
     * Updates the notification statuses with any new values.
     * @param {Record<string, NotificationRecord>} notificationStatus
     * @param {PSIAchievementLevel[]} achievementLevels
     * @returns {Promise<Record<string, NotificationRecord>>}
     * @memberof AchievementChartComponent
     */
    public async updateNotificationStatus(notificationStatus: Record<string, NotificationRecord>, achievementLevels: PSIAchievementLevel[]): Promise<Record<string, NotificationRecord>> {
        for (let achievementLevel of achievementLevels) {

            //Copy from the achievement level
            let aspect: string = achievementLevel?.aspect?.id
            let value: any = achievementLevel?.level
            if (!aspect) continue;

            //Check if notification status exists
            if (notificationStatus[aspect]) {

                //Update the notification status if it has a new value
                if (notificationStatus[aspect].previousValue != value) {
                    notificationStatus[aspect] = {
                        previousValue: value,
                        hasChecked: false
                    }
                }
            } else {

                //Create the notification status
                notificationStatus[aspect] = {
                    previousValue: value,
                    hasChecked: false
                }

            }
        }

        //Save the update notification statuses
        await this._storage.set(DASHBOARD_NOTIFICATION_STORAGE_KEY, notificationStatus)
        return notificationStatus
    }


    public get QualitativeLevel(): typeof QualitativeLevel { return QualitativeLevel; }
    public get ExpertiseLevel(): typeof ExpertiseLevel { return ExpertiseLevel; }

    /**
     * Initializes the achievement graph.
     * @memberof AchievementChartComponent
     */
    initGraph(): void {

        //Get the label information
        const achievementLevels = this.achievementLevels;
        let labels: string[] = [], chartData: number[] = [];
        let minLevel: number = Infinity, maxLevel: number = -Infinity;
        for (let level of achievementLevels) {
            labels.push(this.getPreset(level.aspect.id))
            chartData.push(Math.max(level.level, 1))
            if (level.level < minLevel) minLevel = level.level
            if (level.level > maxLevel) maxLevel = level.level
        }
        const xAxisLabel: string = this.getLabel(this.xAxisLabel);
        const yAxisLabel: string = this.getLabel(this.yAxisLabel);
        const yAxisUnit: string = this.getLabel(this.yAxisUnit);
        const dataLabel: string = this.getLabel(this.dataLabel);
        const competentLabel: string = this.getPreset(`Expertise-${this.expertiseLevel + 1}`);

        //Get the font information
        let { defaultFontFamily, defaultFontSize, labelFontSize, fontColor } = this.getFontData();

        //Create the options
        this.barChartOptions = {
            onClick: (event, activeElements: any[]) => {

                // Swaps the aspect tab when interacting with the chart
                let index: number = activeElements.length ? activeElements[0]._index : -1;
                if (index != -1) {
                    this.activeTab.setValue(index)
                    let aspectDescriptionTabs = document.getElementById("aspectDescription");
                    if (aspectDescriptionTabs) {
                        aspectDescriptionTabs.scrollIntoView({ behavior: "smooth", block: "end" });
                    }
                }

            },
            responsive: true,
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    fontFamily: <string>defaultFontFamily,
                    fontSize: <number>defaultFontSize,
                    fontColor: <string>fontColor,
                    filter: (legendItem, data) => {
                        // Ensure the current data set is the user's current status
                        if (0 === legendItem.datasetIndex) {
                            /** The value of the lowest achievement level. */
                            let lowestAchievementLevel: number = Math.min(...this.achievementLevels.map(level => level.level));

                            /** The expertise associated with the lowest achievement level. */
                            let expertise: ExpertiseLevel = this.getExpertiseFromLevel(lowestAchievementLevel);

                            // Overwrite the current status with the missing expertise level.
                            legendItem.text = this.getPreset(`Expertise-${expertise}`)
                            legendItem.fillStyle = this._expertiseColours[expertise].color.toString();
                            legendItem.strokeStyle = this._expertiseColours[expertise].borderColor.toString();
                        }
                        return true
                    }
                },
                onClick: () => { },
                fullWidth: true
            },
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: xAxisLabel,
                        fontSize: <number>labelFontSize,
                        fontFamily: <string>defaultFontFamily,
                        fontColor: <string>fontColor,
                    },
                    ticks: {
                        fontColor: <string>fontColor
                    },
                    gridLines: {
                        display: false
                    },

                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: yAxisLabel,
                        fontSize: <number>labelFontSize,
                        fontFamily: <string>defaultFontFamily,
                        fontColor: <string>fontColor
                    },
                    ticks: {
                        display: false,
                        suggestedMin: this.minimum,
                        fontSize: <number>defaultFontSize,
                        fontFamily: <string>defaultFontFamily,
                        fontColor: <string>fontColor,

                    }
                }]
            },
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                mode: "single",
                titleFontSize: <number>defaultFontSize,
                titleFontStyle: "normal",
                bodyFontSize: Math.ceil(<number>defaultFontSize / LABEL_SCALAR),
                bodyFontFamily: <string>defaultFontFamily,
                callbacks: {
                    label: (tooltipItems, data) => {
                        return this.getPreset(`Expertise-${this.getExpertiseFromLevel(Number(tooltipItems.value))}`)
                    }

                },
                filter: (item, data) => {
                    return 0 === item.datasetIndex
                }
            },
            title: {
                position: "top"
            }
        }

        //Prepare the chart bar colors
        let chartBorderColors = [], chartColors = [], chartHoverColors = []
        for (let value of chartData) {
            let colorIndex: number = 0;
            for (let i in this.expertiseThresholds) {
                let threshold = this.expertiseThresholds[i]
                let thresholdExpertiseLevel = Number(i) + 1
                if (threshold > value || thresholdExpertiseLevel > ExpertiseLevel.Expert) {
                    break
                } else {
                    colorIndex = Number(thresholdExpertiseLevel);
                }
            }
            chartBorderColors.push(this._expertiseColours[colorIndex]?.borderColor || this._expertiseColours[0].borderColor)
            chartColors.push(this._expertiseColours[colorIndex]?.color || this._expertiseColours[0].color)
            chartHoverColors.push(this._expertiseColours[colorIndex]?.hoverColor || this._expertiseColours[0].hoverColor)
        }

        //Set the chart lens data
        let tempBarChartData: ChartDataSets[] = [
            {
                data: chartData,
                label: dataLabel,
                order: 1,
                borderColor: chartBorderColors,
                backgroundColor: chartColors,
                hoverBackgroundColor: chartHoverColors,
                hideInLegendAndTooltip: true
            }
        ]

        //Add the expertise bars
        for (let i in this.expertiseThresholds) {
            let threshold = this.expertiseThresholds[i]
            let thresholdExpertiseLevel = Number(i) + 1
            if (threshold < minLevel || thresholdExpertiseLevel > ExpertiseLevel.Expert) continue;
            tempBarChartData.push({
                data: new Array(labels.length).fill(threshold),
                label: this.getPreset(`Expertise-${thresholdExpertiseLevel}`),
                type: "line",
                fill: false,
                order: 2,
                pointRadius: 0,
                hoverRadius: 0,
                borderColor: this._expertiseColours[thresholdExpertiseLevel]?.color || this._expertiseColours[0],
            })
            if (maxLevel < threshold) break
        }


        this.barChartData = tempBarChartData;

        //Update additional options
        this.barChartLabels = labels;
        this.barChartLegend = this.isLegend;

    }

    /**
     * Calculated and returns the font sizes and families for the graph.
     * @returns {Record<string, number>}
     * @memberof AchievementChartComponent
     */
    getFontData(): Record<string, number | string> {
        let defaultFontFamily: string = "Ariel";
        let defaultFontSize: number = DEFAULT_FONT_SIZE, labelFontSize: number;
        let fontColor: string = "#333";
        if (this.chartContainer) {
            let fontSize = getComputedStyle(this.chartContainer.nativeElement).fontSize;
            let fontFamily = getComputedStyle(this.chartContainer.nativeElement).fontFamily;

            defaultFontSize = Number(fontSize.slice(0, -2));
            defaultFontFamily = fontFamily
        }
        labelFontSize = Math.floor(defaultFontSize * LABEL_SCALAR);
        return {
            defaultFontFamily,
            defaultFontSize,
            fontColor,
            labelFontSize,
        }
    }

    /**
     * Calculates the appropriate expertise level based on the provided achievement level and available expertise thresholds.
     * @param {number} achievementLevel The achievement level.
     * @returns {ExpertiseLevel}
     * @memberof AchievementChartComponent
     */
    getExpertiseFromLevel(achievementLevel: number, expertiseThresholds?: number[]): ExpertiseLevel {
        let thresholds = expertiseThresholds ?? this.expertiseThresholds;
        let expertise = ExpertiseLevel.Novice;
        for (let i in thresholds) {
            if (thresholds[i] > achievementLevel) break;
            expertise = Number(i) + 1
        }
        return expertise;
    }

    getPreset(text: string): string {
        let selectorRegex = new RegExp("^[a-zA-Z0-9\_\-]+$");
        if ("string" != typeof text || !text.length) return ""
        if (!text.match(selectorRegex)) return text;
        let chartTextPresets: HTMLDivElement = this.textPresets.nativeElement;
        let chartTextPreset: HTMLDivElement | null = chartTextPresets.querySelector(`#${text}`)
        if (!chartTextPreset) return text
        return chartTextPreset.innerText;
    }

    formatTextForToolTip(text: string, maxCharacters: number = 36) {
        let words = text.split(" ");
        let lineLength = 0;
        let output = "";
        for (let word of words) {
            if (lineLength + word.length > maxCharacters) {
                lineLength = 0;
                output += "\n";
            }

            lineLength += word.length;
            output += word + " ";
        }
        return output
    }

    /**
     * Pulls the inner html from an html element reference.
     * @param elem The html element reference.
     * @returns {string} The inner html from the element.
     */
    getLabel(elem: ElementRef): string {
        let label: string = "";
        if (!elem) return label;
        if (!elem.nativeElement) return label;
        if (!elem.nativeElement.innerHTML || "string" != typeof elem.nativeElement.innerHTML) return label;
        label = elem.nativeElement.innerHTML
        return label;
    }

    /**
     * Calculates the user's overall qualitative achievement level based on the provided achievement levels. Assumes six achievement levels.
     * @param {PSIAchievementLevel[]} achievementLevels The provided achievement levels.
     * @returns {QualitativeLevel} The user's overall qualitative achievement level.
     * @memberof AchievementChartComponent
     */
    getOverallQualitativeLevel(achievementLevels?: PSIAchievementLevel[]): QualitativeLevel {
        achievementLevels = achievementLevels || this.achievementLevels;
        let quantitativeValue: number = 0;

        //Calculate the total quantitative value from the qualitative achievement levels
        for (let achievementLevel of achievementLevels) {
            let qualitativeLevel = this.getAchievementQualitativeLevel(achievementLevel.offset);
            if ([QualitativeLevel.Good, QualitativeLevel.Great, QualitativeLevel.High].includes(qualitativeLevel)) {
                quantitativeValue += 3
            } else if ([QualitativeLevel.Close].includes(qualitativeLevel)) {
                quantitativeValue += 2
            } else {
                quantitativeValue += 1
            }
        }

        //Calculate the qualitative level
        if (quantitativeValue < 5) return QualitativeLevel.Low
        if (quantitativeValue < 8) return QualitativeLevel.Close
        if (quantitativeValue < 11) return QualitativeLevel.Good
        if (quantitativeValue < 13) return QualitativeLevel.Great
        return QualitativeLevel.High
    }

    getAchievementQualitativeLevel(achievementLevel: number): QualitativeLevel {
        if (achievementLevel < 70) return QualitativeLevel.Low
        if (achievementLevel < 100) return QualitativeLevel.Close
        if (achievementLevel < 124) return QualitativeLevel.Good
        if (achievementLevel < 149) return QualitativeLevel.Great
        return QualitativeLevel.High
    }


    /**
     * Returns the aspect with the lowest value. If tied it will return the first occurance.
     * @readonly
     * @type {string}
     * @memberof AchievementChartComponent
     */
    public getWeakestAspect(): string {
        let lowestAspect: string = "";
        let lowestAspectValue: number;
        for (let achievementLevel of this.achievementLevels) {

            //Copy the first aspect
            if (!lowestAspect) {
                lowestAspect = achievementLevel.aspect.id
                lowestAspectValue = achievementLevel.level;
                continue;
            }

            //Skip if the current value is higher
            if (lowestAspectValue <= achievementLevel.level) continue;


            //Copy the aspect
            lowestAspect = achievementLevel.aspect.id
            lowestAspectValue = achievementLevel.level;

        }
        return lowestAspect;
    }

    /**
     * Returns the qualitative level for the provided aspect.
     * @param {string} aspectName
     * @returns {QualitativeLevel}
     * @memberof AchievementChartComponent
     */
    public getAspectQualitativeLevel(aspectName: string): QualitativeLevel {
        let aspect: PSIAchievementLevel = this.achievementLevels.find((a) => a.aspect.id == aspectName)
        if (!aspect) return QualitativeLevel.Low;
        return this.getAchievementQualitativeLevel(aspect.offset)
    }

    /**
     * Returns the list of available learning aspects.
     * @returns {string[]}
     * @memberof AchievementChartComponent
     */
    public getLearningAspects(): string[] {
        let learningAspect: string[] = [];
        for (let level of this.achievementLevels) {
            learningAspect.push(level.aspect.id)
        }
        return learningAspect
    }
}



interface NotificationRecord {

    /** The previous value. Used to check if the notification */
    previousValue: any;

    /** If the user has checked the notification since it was previously saved. */
    hasChecked: boolean;

}