import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { CaptureRedirectError, DashboardClient, UserDashboard, ProgressClient, LensProgressResponse } from "./backendClient";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

const POLL_INTERVAL: number = 60000;

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private _dashboardClient: DashboardClient;
    private _progressClient: ProgressClient;

    private _cachedModules: UserDashboard;

    private _dashboardBehaviourSubject: BehaviorSubject<UserDashboard>;
    private _lensBehaviourSubject: BehaviorSubject<LensProgressResponse>;

    constructor(
        private _authService: AuthService,
        router: Router
    ) {
        this._dashboardClient = new DashboardClient(router, environment.apiUrl);
        this._progressClient = new ProgressClient(router, environment.apiUrl);
        this.reset();
        this._authService.isAuthenticated().subscribe((isAuthenticated) => {
            if(!isAuthenticated) this.reset();
        })
    }

    public get dashboardBehaviourSubject(): BehaviorSubject<UserDashboard> { return this._dashboardBehaviourSubject }
    public get lensBehaviourSubject(): BehaviorSubject<LensProgressResponse> { return this._lensBehaviourSubject }

    public async getHome(options: GetHomeOptions = {}): Promise<UserDashboard | null> {
        if (options.useCache && this._cachedModules) return Promise.resolve(this._cachedModules);
        this._dashboardClient.token = await this._authService.getToken();

        return await CaptureRedirectError(async () => {
            let modules: UserDashboard = await this._dashboardClient.home();

            this._dashboardBehaviourSubject.next(modules);

            //Cache the modules to use with the login process
            this._cachedModules = this._dashboardBehaviourSubject.value
            return this._dashboardBehaviourSubject.value
        })
    }

    public async getLens(): Promise<LensProgressResponse | null> {
        this._progressClient.token = await this._authService.getToken();
        return await CaptureRedirectError(async () => {
            let response: LensProgressResponse = await this._progressClient.getLensValues();
            if (response)
                this._lensBehaviourSubject.next(response);
            return this._lensBehaviourSubject.value
        })
    }

    public reset() {
        console.log("Resetting dashboard contents");
        this._dashboardBehaviourSubject = new BehaviorSubject(null);
        this._lensBehaviourSubject = new BehaviorSubject(null);
    }

}

interface GetHomeOptions {
    useCache?: boolean;
}