<div class="tutorial-blocker" (click)="progressTutorial()" *ngIf="isTutorialRunning"></div>

<!-- Toolbar -->
<psi-toolbar *ngIf="isLoggedIn" [drawer]="drawer" [isChartNotification]="isNotification" [isLimitedRoute]="isLimitedRoute"></psi-toolbar>

<mat-drawer-container [class.toolbar-padding]="isLoggedIn">

    <!-- Side bar with learning aspects -->
    <mat-drawer #drawer class="psi-sidenav" [class.toolbar-padding]="isLoggedIn" mode="over" hasBackdrop="true" position="end" role="region" autoFocus="false">
        <psi-achievement-chart *ngIf="isLoggedIn && !isLimitedRoute.value" minimum="0" maximum="100" [isChartNotification]="isNotification"></psi-achievement-chart>
    </mat-drawer>

    <!-- Page contents -->
    <router-outlet></router-outlet>

</mat-drawer-container>
