import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

const STORAGE_PREFIX: string = "ps-";

@Injectable({
    providedIn: 'root'
})
export class StorageService {


    constructor(
        private _storage: LocalStorage
    ) { }

    /** Retrieves an item from local storage. */
    get<T>(key: string): Promise<T | null> {
        return new Promise((resolve, reject) => {
            let subscription: Subscription;
            subscription = this._storage.getItem(STORAGE_PREFIX + key).subscribe((value: T) => {
                if (subscription) subscription.unsubscribe();
                if ("undefined" !== typeof value) {
                    return resolve(value)
                } else {
                    return resolve(null)
                }
            }, (e) => {
                if (subscription) subscription.unsubscribe();
                return reject(e);
            })
        })
    }

    /** Stores an item from local storage. */
    set<T>(key: string, value: T): Promise<T | null> {
        return new Promise((resolve, reject) => {
            let subscription: Subscription;
            let valueToStore: T = value;
            if ("object" === typeof value && null !== value)
                valueToStore = JSON.parse(JSON.stringify(value))
            subscription = this._storage.setItem(STORAGE_PREFIX + key, valueToStore).subscribe(() => {
                if (subscription) subscription.unsubscribe();
                if ("undefined" !== typeof valueToStore) {
                    return resolve(valueToStore)
                } else {
                    return resolve(null)
                }
            }, (e) => {
                if (subscription) subscription.unsubscribe();
                return reject(e);
            })
        })
    }

    /** Removes an item from local storage. */
    delete(key: string): Promise<void> {
        return new Promise((resolve, reject) => {
            let subscription: Subscription;
            subscription = this._storage.removeItem(STORAGE_PREFIX + key).subscribe(() => {
                if (subscription) subscription.unsubscribe();
                return resolve()
            }, (e) => {
                if (subscription) subscription.unsubscribe();
                return reject(e);
            })
        })
    }

}
