<mat-toolbar>
    <div id="home" (click)="gotoDashboard()" title="Dashboard">
        <img src="assets/svg/logo.svg" />
    </div>
    <span class="toolbar-spacer"></span>

    <!-- Feedback Button -->
    <div *ngIf="!isLimitedRoute.value">
        <button mat-raised-button color='primary' class="feedback" (click)="gotoFeedbackSurvey()">
            <span i18n> Provide Feedback </span>
            <mat-icon class="material-icons-outlined">reviews</mat-icon>
        </button>
        <div class="tutorial-element no-select" id="tutorial-notification" tutorialOrder="2" i18n>
            Use the  Provide Feedback <mat-icon class="material-icons-outlined">reviews</mat-icon> button to provide system or module-related suggestions, ideas, problems, or questions.
        </div>
    </div>

    <!-- Notification Button -->
    <div *ngIf="!isLimitedRoute.value">
        <button mat-icon-button [matMenuTriggerFor]="messages" title="Notifications">
            <mat-icon aria-label="Messages" [matBadge]="notifications?.value?.length" [matBadgeHidden]="notifications?.value?.length === 0" matBadgeSize="small" matBadgePosition="after" matBadgeColor="warn">inbox</mat-icon>
        </button>
        <div class="tutorial-element no-select" id="tutorial-notification" tutorialOrder="2">
            Your inbox <mat-icon>inbox</mat-icon> contains personalized messages and reminders from the Advisor.
        </div>
    </div>

    <!-- Notification List -->
    <mat-menu #messages [overlapTrigger]="false" class="notification-container">
        <div *ngFor="let notify of notifications.value" class="notification">
            <div (click)="triggerNotification(notify); $event.stopPropagation()" class="mat-menu-item-inline" class="notification-content">
                <h3 *ngIf="notify?.shortDescription">
                    {{ runTemplate(notify?.shortDescription, notify?.data) }}
                </h3>
                <div *ngIf="notify?.description">
                    {{ runTemplate(notify?.description, notify?.data) }}
                </div>
                <div *ngIf="notify?.date" class="notification-date">
                    {{ dateToRelativeString(notify?.date) }}
                </div>
            </div>

            <button mat-icon-button (click)="dismissNotification(notify); $event.stopPropagation()" class="notification-close">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

        <button mat-menu-item disabled *ngIf="notifications.value.length === 0">
            <span i18n>No reminders</span>
        </button>
    </mat-menu>

    <!-- Advisor Button -->
    <div *ngIf="!isLimitedRoute.value">
        <button mat-icon-button *ngIf="drawer" (click)="drawer?.toggle()">
            <mat-icon aria-label="Advisor" class="material-icons-outlined" [class.icon-notification]="isChartNotification?.value" title="Advisor">view_sidebar</mat-icon>
        </button>
        <div class="tutorial-element no-select" id="tutorial-notification" tutorialOrder="2" i18n>
            The Advisor <mat-icon class="material-icons-outlined">view_sidebar</mat-icon> is your AI-supported personal learning coach. It will guide you on your learning journey.
        </div>
    </div>

    <!-- Logout Button -->
    <button mat-icon-button (click)="logout()">
        <mat-icon aria-label="Logout" title="Log Out">logout</mat-icon>
    </button>

</mat-toolbar>