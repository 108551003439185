import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginModel } from '@shared/types/Authorization';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AccountClient, CaptureRedirectError, JwtAuthResult, LoginModel, RefreshModel, RegisterModel } from './backendClient';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

const LOGIN_PATH: string = "/";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _client: AccountClient;
    private _expiry: number;

    constructor(
        private _storage: StorageService,
        private _router: Router
    ) {
        this._client = new AccountClient(_router, environment.apiUrl);
        this.initialize();
    }

    protected async initialize() {
        try {
            const initializationPromises: Promise<any>[] = [
                this.updateExpiry(null)
            ]

            await Promise.all(initializationPromises);

            if (null !== await this.getToken()) {
                this._isAuthenticated.next(true);
            }

        } catch (e) {
            console.error(e);
        }

        this._isInitialized.next(true);
    }

    async login(credentials: ILoginModel): Promise<any> {
        const jwt = await this._client.login(new LoginModel({
            username: credentials.username,
            password: credentials.password
        }));

        await this.updateTokens(jwt);
        this._isAuthenticated.next(true);
    }

    async logout(): Promise<void> {
        this._isAuthenticated.next(false);
        this._expiry = Date.now();
        await this._storage.delete("accessToken");
        await this._storage.delete("refreshToken");

        this._router.navigate([LOGIN_PATH])
    }

    async refreshToken(): Promise<void> {
        console.log("Refreshing token");

        try {
            const refreshToken = await this._storage.get("refreshToken");
            this._client.token = await this._storage.get<string>("accessToken");
            const jwt = await this._client.refresh(new RefreshModel({
                refreshToken: refreshToken["tokenString"]
            }));

            await this.updateTokens(jwt);
        } catch (e) {
            console.log("Refresh failed, logging out.");
            await this.logout();
        }
    }

    async getToken(): Promise<string> {
        if (this._expiry < Date.now()) {
            console.log("JWT is expired");
            await this.refreshToken();
        }

        return await this._storage.get<string>("accessToken");
    }

    async register(registration: RegisterModel): Promise<any> {
        this._client.token = await this.getToken();

        return await CaptureRedirectError(async () => await this._client.register(registration));
    }

    isAuthenticated(): BehaviorSubject<boolean> {
        return this._isAuthenticated;
    }

    isInitialized(): BehaviorSubject<boolean> {
        return this._isInitialized;
    }

    private async updateTokens(jwt: JwtAuthResult): Promise<void> {
        await this._storage.set("accessToken", jwt.accessToken);
        await this._storage.set("refreshToken", jwt.refreshToken);

        await this.updateExpiry(jwt.accessToken);
    }

    private async updateExpiry(token: string | null): Promise<any> {
        if (!token) {
            token = await this._storage.get<string>("accessToken")
        }

        if (!token) {
            this._expiry = 0;
            return;
        }

        const jwt = jwtDecode<any>(token, { header: false });
        this._expiry = jwt.exp * 1000;
        console.log("JWT expires at", new Date(this._expiry));
    }
}
