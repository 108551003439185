import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@shared/components.module';
import { MaterialModule } from '@shared/material.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingComponent } from './landing/landing.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { ModulePreviewComponent } from './module-preview/module-preview.component';
import { ActiveModuleComponent } from './active-module/active-module.component';
import { ActionPlanReviewComponent } from './action-plan-review/action-plan-review.component';
import { ReassessmentComponent } from './reassessment/reassessment.component';
import { FeedbackComponent } from './feedback/feedback.component';

@NgModule({
    declarations: [
        LoginComponent,
        RegistrationComponent,
        DashboardComponent,
        LandingComponent,
        OnboardingComponent,
        ModulePreviewComponent,
        ActiveModuleComponent,
        ActionPlanReviewComponent,
        ReassessmentComponent,
        FeedbackComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
    ],
    exports: []
})
export class CoreModule { }
