import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SurveyComponent } from './components/survey/survey.component';
import { MaterialModule } from './material.module';
import { AchievementChartComponent } from './components/achievement-chart/achievement-chart.component';
import { ChartsModule } from 'ng2-charts';
import { QuizComponent } from './components/quiz/quiz.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { ActionPlanComponent } from './components/action-plan/action-plan.component';
import { DynamicSectionContentComponent } from './components/dynamic-section-content/dynamic-section-content.component';
import { ReplaceLinksPipe } from './pipes/replace-links.pipe';
import { NestedPointsComponent } from './components/nested-points/nested-points.component';
import { HTMLEncodePipe } from './pipes/html-encode.pipe';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule
    ],
    declarations: [
        SurveyComponent,
        AchievementChartComponent,
        QuizComponent,
        VideoPlayerComponent,
        ActionPlanComponent,
        SanitizePipe,
        ReplaceLinksPipe,
        DynamicSectionContentComponent,
        NestedPointsComponent,
        HTMLEncodePipe,
        ToolbarComponent
    ],
    exports: [
        SurveyComponent,
        AchievementChartComponent,
        QuizComponent,
        VideoPlayerComponent,
        ActionPlanComponent,
        SanitizePipe,
        ReplaceLinksPipe,
        DynamicSectionContentComponent,
        NestedPointsComponent,
        HTMLEncodePipe,
        ToolbarComponent
    ]
})
export class ComponentsModule { }
